/* Default styling for smaller screens (width less than or equal to 371px) */
.slick-slider {
  width: 100%;
}

.carousel-container {
  margin-bottom: 16px;
  
  padding-top: 16px;

  
}

/* Media query for screens with width greater than 371px */
@media (min-width: 414px) {
  .slick-slider {
    width: 371px;
    margin: auto;
    margin-bottom: 16px;
  }
}

.slick-slide img {
  width: 100%;
  height: 132px;
  object-fit: cover;
  border-radius: 8px;
}

.ft-slick__dots--custom {
  height: 5px;
  width: 28px;
  background-color: #FEE5CE;
  border-radius: 10px;
  position: relative;
}

.slick-dots li {
  width: 33px;
  margin: 0 2px;
  transition: width 0.3s ease-in-out;
}

.slick-dots li.slick-active .ft-slick__dots--custom {
  background-color: #FB7E08;
}
