* {
  box-sizing: border-box;
}

:root {
  --font-Sans: "Open Sans";
}

body {
  font-family: var(--font-Sans);
}

input::placeholder {
  color: #ababab;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

*::-webkit-input-placeholder {
  font-family: "Open Sans";
}

.font400 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
